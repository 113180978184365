<template>
  <div class="app-calendar overflow-hidden border">
    <b-overlay
      variant="transparent"
      opacity="1.00"
      blur="4px"
      :show="loading"
      rounded="sm"
    >
      <div class="row no-gutters">
        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              />
            </div>
          </div>
        </div>

        <!-- Sidebar Overlay -->
        <div
          class="body-content-overlay"
          :class="{ show: isCalendarOverlaySidebarActive }"
          @click="isCalendarOverlaySidebarActive = false"
        />
        <calendar-event-handler
          v-model="isEventHandlerSidebarActive"
          :event="event"
          :clear-event-data="clearEventData"
          @remove-event="removeEvent"
          @add-event="addEvent"
          @update-event="updateEvent"
        />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { BOverlay } from "bootstrap-vue";
import FullCalendar from "@fullcalendar/vue";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import calendarStoreModule from "./calendarStoreModule";
import CalendarSidebar from "./calendar-sidebar/CalendarSidebar.vue";
import CalendarEventHandler from "./calendar-event-handler/CalendarEventHandler.vue";
import useCalendar from "./useCalendar";
import calendarLocale from "@/@core/utils/calendar-locale";
import router from "@/router";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
    BOverlay,
  },

  watch: {
    "$i18n.locale": function () {
      this.refreshLocale();
    },
  },

  methods: {
    refreshLocale() {
      this.calendarOptions.locale = calendarLocale(this.$i18n.locale);
    },
  },

  data() {
    return {
      loading: true,
    };
  },

  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 2500);
  },

  setup() {
    //const { t } = useI18nUtils();

    const CALENDAR_APP_STORE_MODULE_NAME = "calendar";

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
        store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME);
    });

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar();

    // Get current locale from setup()
    //console.log(router.app.$i18n.locale);
    calendarOptions.value.locale = calendarLocale(router.app.$i18n.locale);

    fetchEvents();

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
