<template>
  <b-sidebar
    id="add-new-bill-sidebar"
    :visible="isAddNewBillSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-bill-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("message.bills.createNewBills") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitNewbill())"
          @reset.prevent="resetForm"
        >
          <!-- Type -->
          <validation-provider
            #default="validationContext"
            name="tipo"
            rules="required"
          >
            <b-form-group
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.tasks.type") }}
              </template>
              <v-select
                :on-change="selectedType()"
                v-model="billData.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="bill-role"
              />
            </b-form-group>
          </validation-provider>

          <!-- Day -->
          <validation-provider
            v-if="activeDay"
            #default="validationContext"
            name="dia"
            rules="required"
          >
            <b-form-group label-for="day">
              <template v-slot:label>
                {{ $t("message.bills.launchDay") }}
              </template>
              <flat-pickr
                v-model="billData.expiryDay"
                :config="flatConfigDay"
                class="form-control"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                <template>
                  {{ $t("message.list.fieldRequired") }}
                </template>
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Date -->
          <validation-provider
            v-if="activeDate"
            #default="validationContext"
            name="dia"
            rules="required"
          >
            <b-form-group label-for="date">
              <template v-slot:label>
                {{ $t("message.bills.expiryDate") }}
              </template>
              <flat-pickr
                v-model="billData.expiryDate"
                :config="flatConfig"
                class="form-control"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                <template>
                  {{ $t("message.list.fieldRequired") }}
                </template>
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- title -->
          <validation-provider
            #default="validationContext"
            name="descrição"
            rules="required"
          >
            <b-form-group
              label="Descrição"
              label-for="title"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.projects.description") }}
              </template>
              <quill-editor
                id="title"
                style="margin-bottom: 82px; height: 300px"
                :options="editorOption"
                v-model="billData.title"
              />
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!disabledButton"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-else
              disabled
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("message.buttons.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import date from "@/@core/utils/date";
import dateInput from "@/@core/utils/dateInput";
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormSpinbutton,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewBillSidebarActive",
    event: "update:is-add-new-bill-sidebar-active",
  },
  props: {
    isAddNewBillSidebarActive: {
      type: Boolean,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      activeDate: false,
      activeDay: false,
      flatConfig: {
        dateFormat: "d/m/Y",
      },
      flatConfigDay: {
        dateFormat: "j",
      },

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      //Param
      paramData: store.state.user.paramData,
      userData: store.state.user.userData,

      disabledButton: false,
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInput(newVal);
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInput(this.$i18n.locale);
    }
  },

  methods: {
    submitNewbill() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.disabledButton = true;
          this.onSubmit();

          if (this.billData.expiryDate != null) {
            this.billData.expiryDate = date(
              this.$i18n.locale,
              this.billData.expiryDate
            );
          }

          axios({
            method: "post",
            url: `${URL_API}bill`,
            headers: getHeader(this.userData),
            data: this.billData,
          })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$i18n.t("message.toast.create"),
                  icon: "CheckIcon",
                  variant: "success",
                  text: `Conta criada com sucesso`,
                },
              });
            })
            .then(() => {
              this.$root.$emit("newListBill", null);
              this.disabledButton = false;
            })
            .catch((error) => {
              this.disabledButton = false;
              console.log(error);
            });
        }
      });
    },

    selectedType() {
      if (this.billData.type === "isolate") {
        this.activeDate = true;
        this.activeDay = false;
      } else {
        this.activeDate = false;
        this.activeDay = true;
      }
    },
  },

  setup(props, { emit }) {
    const blankbillData = {};

    const billData = ref(JSON.parse(JSON.stringify(blankbillData)));
    const resetbillData = () => {
      billData.value = JSON.parse(JSON.stringify(blankbillData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-bill-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetbillData);

    return {
      billData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-bill-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
