import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import moment from "moment";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: "success",
        label: "Tarefa",
      },
      {
        color: "warning",
        label: "Lancamento",
      },
    ],
    selectedCalendars: ["Tarefa", "Lancamento"],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val;
    },
  },
  actions: {
    fetchEvents(ctx, { calendars }) {
      return new Promise((resolve, reject) => {
        //CurrentUser
        var userData = store.state.user.userData;

        if (calendars.length === 0) {
          calendars.push("_NONE");
        }

        axios
          .get(`${URL_API}bill/calendar`, {
            headers: getHeader(userData),
          })
          .then((response) => {
            var objFinal = [];
            let size = response.data.length;

            var date = new Date();
            var y = date.getFullYear();

            for (var i = 0; i < size; i++) {
              if (response.data[i].type === "recurrent") {
                for (var j = 0; j < 12; j++) {
                  var finalDate =
                    y + "/" + (j + 1) + "/" + response.data[i].expiryDay;
                  objFinal.push({
                    id: response.data[i].id,
                    title: response.data[i].title.replace(/<[^>]+>/g, ""),
                    type: response.data[i].type,
                    expiryDay: response.data[i].expiryDay,
                    tenant: response.data[i].tenant,
                    start: moment(new Date(finalDate)).format("YYYY-MM-DD"),
                    end: moment(new Date(finalDate)).format("YYYY-MM-DD"),
                  });
                }
              } else {
                objFinal.push({
                  id: response.data[i].id,
                  title: response.data[i].title.replace(/<[^>]+>/g, ""),
                  type: response.data[i].type,
                  expiryDay: response.data[i].expiryDay,
                  tenant: response.data[i].tenant,
                  start: response.data[i].expiryDate,
                  end: response.data[i].expiryDate,
                });
              }
            }
            response.data = objFinal;
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios({
          method: "post",
          url: `${URL_API}calendar`,
          headers: getHeader(userData),
          data: event,
        })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios({
          method: "put",
          url: `${URL_API}calendar/${event.id}`,
          headers: getHeader(userData),
          data: event,
        })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios({
          method: "delete",
          url: `${URL_API}calendar/${id}`,
          headers: getHeader(userData),
        })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
